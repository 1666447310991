import React from "react"

import { Card } from "components/anti/card/card"

import covid19Cover from "assets/img/covid-19-test-banner.jpg"
import imgDefault from "assets/img/bg_default.jpg"

export const ProgramList = ({ data }) => {
  return (
    <section className="py-main sc-aman-update-content">
      <div className="container">
        <div className="row row-3">
          {data.map((program, i) => {
            return (
              <div className={`col-md-6 col-lg-4`} key={i}>
                <Card
                  variant="boxless"
                  img={program?.featuredImage?.node?.sourceUrl || imgDefault}
                  link={`/program/${program.slug}`}
                  title={program.title}
                  label={program?.programDetails?.masaBerlaku?.text}
                  className={`fadeInUp d${1 + i} card-update hover-zoomIn`}
                  labelClassName={`fadeInUp d${2 + i}`}
                  titleClassName={`fadeInUp d${3 + i} h5`}
                />
              </div>
            )
          })}
          <div className={`col-md-6 col-lg-4`}>
            <Card
              variant="boxless"
              img={covid19Cover}
              link={`/covid-19-test`}
              title={"Covid-19 Test"}
              // label={"Aug 22, 2021"}
              className={`fadeInUp d${2} card-update hover-zoomIn`}
              labelClassName={`fadeInUp d${3}`}
              titleClassName={`fadeInUp d${4} h5`}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
