import React from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Cover } from "components/anti/cover/cover"
import { Image } from "components/anti/image/image"

export const WFBCover = ({ data }) => {
  const [trigger, anim] = useScrollAnim()

  return (
    <div ref={trigger} className="cover-full">
      <Cover
        variant="basic"
        theme="dark"
        img={data?.image?.sourceUrl}
        imgHeight="r-3-4 h-sm-500px"
        className={`sc-wfb-cover animated fadeInUp mb-sm-down-5`}
        textClassName={`${anim(2)}`}
        titleClassName={`${anim(3)} font-weight-normal mb-4`}
        label={data?.label}
        title={data?.title}
        text={data?.text}
        contentMaxWidth="mb-md-down-5 mw-md-450px"
      />
    </div>
  )
}
