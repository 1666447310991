import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Seo from "components/seo"
import Layout from "components/layout"
// import { UpdateFeatured } from "components/pages/aman-update/featured"
import { WFBCover } from "components/pages/wfb/cover"
import { ProgramList } from "components/pages/programs/list"

const Programs = ({ pageContext, path }) => {
  const lang = pageContext.langKey
  const data = useStaticQuery(GET_PROGRAMS_DETAILS)
  const programsList = data.wordPress.programs.nodes
  const cover = data.wordPress.page.programPage.cover

  return (
    <Layout lang={lang} path={path}>
      <Seo title="Program" />
      <WFBCover data={cover} />
      {/* <UpdateFeatured data={featured} /> */}
      <ProgramList data={programsList} />
    </Layout>
  )
}

export default Programs

const GET_PROGRAMS_DETAILS = graphql`
  query programs {
    wordPress {
      page(id: "program", idType: URI) {
        title
        programPage {
          cover {
            title
            text
            image {
              sourceUrl
            }
          }
        }
      }
      programs {
        nodes {
          slug
          date
          title
          featuredImage {
            node {
              sourceUrl
            }
          }
          programDetails {
            masaBerlaku {
              text
            }
          }
        }
      }
    }
  }
`
